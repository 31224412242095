import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styled from 'styled-components';

const CropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) { // Adjust the pixel value based on your design needs
    flex-direction: row;
    align-items: flex-start;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; // Space between controls and cropper
`;

const Title = styled.h5`
  margin-bottom: 10px; // Space below the title
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const CroppedImageDisplay = styled.img`
  max-height: 200px; // Maximum height
  max-width: 200px;  // Maximum width
  width: auto;       // Maintains the aspect ratio
  height: auto;      // Maintains the aspect ratio
  margin-top: 10px;
`;

const ImageCropperWithButton = ({ title, requiredWidth, requiredHeight, onImageCropped }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState(null);

  const aspectRatio = requiredWidth / requiredHeight;

  const handleCrop = (croppedImageData) => {
    // Call the callback prop with the cropped image data
    onImageCropped(title, croppedImageData);
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); // Set the image in the state to the file read as a data URL
        setCroppedImage(null); // Reset the cropped image
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  

  useEffect(() => {
    if (croppedImage) {
      console.log("Cropped Image Updated: ", croppedImage);
      // Perform any additional actions here that need to happen right after croppedImage is updated
    }
  }, [croppedImage]); // Dependency array with croppedImage

  const onCrop = () => {
    if (cropper) {
        const croppedCanvas = cropper.getCroppedCanvas({
            width: requiredWidth,
            height: requiredHeight,
        });
        const croppedImageData = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageData);
        handleCrop(croppedImageData); // Ensure this is called correctly
    }
};
  
  return (
    <CropperContainer>
      <ControlsContainer>
        <Title>{title}</Title>
        {!croppedImage && (
          <>
            <input
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: 'none' }}
              id={`fileInput-${title}`}
            />
            <StyledButton
              variant="primary"
              onClick={() => document.getElementById(`fileInput-${title}`).click()}
            >
              Choose Image
            </StyledButton>
          </>
        )}
        {!croppedImage && imageSrc && (
          <StyledButton variant="success" onClick={onCrop}>
            Crop
          </StyledButton>
        )}
      </ControlsContainer>
      <div>
        {!croppedImage && imageSrc && (
          <Cropper
            src={imageSrc}
            style={{ height: 400, width: 400 }}
            aspectRatio={aspectRatio}
            guides={false}
            zoomable={true}
            viewMode={1}
            autoCropArea={1}
            cropBoxResizable={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        )}
        {croppedImage && <CroppedImageDisplay src={croppedImage} alt="Cropped" />}
      </div>
    </CropperContainer>
  );
};

export default ImageCropperWithButton;
