import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Corrected import for storage

const firebaseConfig = {
  apiKey: "AIzaSyDskZtpccXyatK7GQxsfO7OFbsdnUATVp0",
  authDomain: "stuntapp-6df1a.firebaseapp.com",
  projectId: "stuntapp-6df1a",
  storageBucket: "stuntapp-6df1a.appspot.com",
  messagingSenderId: "877669679832",
  appId: "1:877669679832:web:0ec6d0a9391613dcbc0dad",
  measurementId: "G-QKR5JMRBDX"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app); // Use getStorage with the app instance

export { db, auth, googleProvider, storage };
