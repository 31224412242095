import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { storage, db } from '../Firebase/firebase-config';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate, useLocation  } from 'react-router-dom';


import '../CSS/css@3.css' // Adjust the path as necessary
import logo from '../stunt-connect-logo.jpg';
import '../App.css'



const Preview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { performer } = location.state || {};


    const [headshotUrl, setHeadshotUrl] = useState('');
    const [fullLengthUrl, setFullLengthUrl] = useState('');
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const [actionShotUrls, setActionShotUrls] = useState([]);
    const [heightCm, setHeightCm] = useState();

    useEffect(() => {
        if (performer) {
            // Now you have access to the performer object here
            console.log(performer);
            setHeadshotUrl(performer.imageUrls.headshot || '');
            setFullLengthUrl(performer.imageUrls.fullbody || ''); // Replace 'fullLength' with correct key if different
            setHeaderImageUrl(performer.imageUrls.profileheader || '');
            if (performer && performer.actionShotUrls) {
              setActionShotUrls(performer.actionShotUrls);
            }
            setHeightCm(Number(performer.heightCm));

        }
    }, [performer]);

    function convertCmToFeetInches(cm) {
      if (isNaN(cm) || cm === null) {
        return "Invalid height";
      }
    
      const inchesTotal = cm / 2.54;
      const feet = Math.floor(inchesTotal / 12);
      const inches = Math.round(inchesTotal % 12);
      return `${feet}' ${inches}"`;
    }
    
    console.log("Height in cm: ", heightCm);
    const heightInFeetAndInches = convertCmToFeetInches(heightCm);
    console.log("Converted height: ", heightInFeetAndInches);
  
    const Done = () => {
      navigate('/done');
  };

    return (
        <FullPageContainer>
            <PageContainer>
                <LogoImage src= { logo } alt="Logo" />
                <Title>Preformer Data Uploader</Title>
                <Subtitle>See below the updated data we have on the system.</Subtitle>
                <SideBySideContainer>
                    {headshotUrl && (
                        <ImageContainer>
                            <ImageLabel>Headshot</ImageLabel>
                            <StyledImage src={headshotUrl} alt="Headshot" />
                        </ImageContainer>
                    )}

                    {fullLengthUrl && (
                        <ImageContainer>
                            <ImageLabel>Full Length</ImageLabel>
                            <StyledImage src={fullLengthUrl} alt="Full Length" />
                        </ImageContainer>
                    )}

                    {headerImageUrl && (
                        <ImageContainer>
                            <ImageLabel>Header Image</ImageLabel>
                            <StyledImage src={headerImageUrl} alt="Header Image" />
                        </ImageContainer>
                    )}
                </SideBySideContainer>
                <Divider />     
                <LeftFloatedLabel>Action Shots</LeftFloatedLabel>    
                <SideBySideContainer>
                    {actionShotUrls.length > 0 && (
                        <ImageContainer>
                            <StyledImage src={actionShotUrls[0]} alt="Headshot" />
                        </ImageContainer>
                    )}
                    {actionShotUrls.length > 1 && (
                        <ImageContainer>
                            <StyledImage src={actionShotUrls[1]} alt="Full Length" />
                        </ImageContainer>
                    )}
                    {actionShotUrls.length > 2 && (
                        <ImageContainer>
                            <StyledImage src={actionShotUrls[2]} alt="Header Image" />
                        </ImageContainer>
                    )}
                </SideBySideContainer>
 
                <Divider />  
             

        <PerformerDetailsContainer>
            <h3>Performer Data:</h3>
            <SideBySideContainer>
                <DataColumn>
                    <DataLabel>Name:</DataLabel>
                    <DataLabel id="name">{ performer.fullName }</DataLabel>
                </DataColumn>

                <DataColumn>
                    <DataLabel>Phone:</DataLabel>
                    <DataLabel id="phone">{ performer.phone }</DataLabel>
                </DataColumn>

                <DataColumn>
                    <DataLabel>Email:</DataLabel>
                    <DataLabel id="email">{ performer.email }</DataLabel>
                </DataColumn>
            </SideBySideContainer>

            <hr className="my-4" />

            <SideBySideContainer>
              <DataColumn>
                <DataLabel>Skills:</DataLabel>
                <DataLabel id="skills" style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '500px' }}>
                  {performer.skills && performer.skills.length > 0 ? performer.skills.join(', ') : 'No skills listed'}
                </DataLabel>
              </DataColumn>
            </SideBySideContainer>



            <hr className="my-4" />

            <SideBySideContainer>
                <DataColumn>
                <DataLabel>Height:</DataLabel>
                <DataLabel id="height">{heightCm ? convertCmToFeetInches(heightCm) : "Height not available"}</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Chest:</DataLabel>
                <DataLabel id="chest">{performer.chest}"</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Collar:</DataLabel>
                <DataLabel id="collar">{performer.collar}"</DataLabel>
                </DataColumn>
            </SideBySideContainer>

            <hr className="my-4" />

            <SideBySideContainer>
                <DataColumn>
                <DataLabel>Waist:</DataLabel>
                <DataLabel id="waist">{performer.waist}"</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Inside Leg:</DataLabel>
                <DataLabel id="insideLeg">{performer.insideLeg}"</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Shoe Size:</DataLabel>
                <DataLabel id="shoeSize">UK {performer.shoeSize}</DataLabel>
                </DataColumn>
            </SideBySideContainer>

            <hr className="my-4" />

            <SideBySideContainer>
                <DataColumn>
                <DataLabel>Eye Color:</DataLabel>
                <DataLabel id="eyeColor">{performer.eyeColor}</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Hair Color:</DataLabel>
                <DataLabel id="hairColor">{performer.hairColor}</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Sex:</DataLabel>
                <DataLabel id="sex">{performer.sex}</DataLabel>
                </DataColumn>
                <DataColumn>
                <DataLabel>Ethnicity:</DataLabel>
                <DataLabel id="sex">{performer.ethnicity}</DataLabel>
                </DataColumn>
            </SideBySideContainer>
        </PerformerDetailsContainer>

        <hr className="my-4" />

        <Button onClick={Done}>Ok</Button>

        </PageContainer>
    </FullPageContainer>
        
    );
};

export default Preview;

// Styled components

const PerformerDetailsContainer = styled.div`
    margin-top: 50px;
    align-items: left;
    width: 100%;
`;

const FullPageContainer = styled.div`
  background-color: #1a1a1a;
  min-height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: calc(100% - 60px); // Subtract 30px margin from each side
  margin: 30px auto; // Apply 30px margin on all sides
  padding: 20px;

  @media (min-width: 768px) {
    width: auto; // Let the container expand more freely
    max-width: 100%; // Remove the max-width restriction
    margin: 30px; // Keep the 30px margin
  }
`;

// Logo Image Component
const LogoImage = styled.img`
  width: 100px; // Set this to your desired size
  height: auto;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin: 10px 0;
`;

const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
`;

const Label = styled.label`
  display: block;
  text-align: left;
`;

const SideBySideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px; // Adjust as needed

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageLabel = styled.label`
  margin-bottom: 10px; // Adjust as needed
`;

const StyledImage = styled.img`
  width: 100%; // Adjust as needed
  max-width: 300px; // Adjust as needed
  height: auto;
  border-radius: 10px; // Optional
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: lightgray; // Adjust the color as needed
  width: 100%; // Adjust the width as needed
  margin: 20px 0; // Adjust the margin as needed
`;

const LeftFloatedLabel = styled.label`
  float: left;
  margin-right: 10px; // Adjust the margin as needed
  font-size: 40px; // Adjust font size as needed
  margin-top: 20px; // Adjust top margin as needed
  display: block; // To ensure the label takes its own line
`;

const PerformerDataContainer = styled.div`
  margin-top: 50px;
`;

const DataSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; // Ensure it takes full width of its parent

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; // Stretch children to take full width
  }
`;


const DataColumn = styled.div`
  flex: 1; // Takes equal space
  padding: 0 10px; // Add some padding for spacing, adjust as needed
  gap: 100px;
  &:first-child {
    padding-left: 0; // Remove padding from the first child
  }

  &:last-child {
    padding-right: 0; // Remove padding from the last child
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 0; // Reset padding on smaller screens
  }
`;

const DataLabel = styled.label`
  display: block; // Make label take full width
  white-space: nowrap; // Prevent text from wrapping
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Add ellipsis for overflowed text
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #712cf9;
  color: white;
  margin-top: 20px;
  cursor: pointer;
`;