import React, { useState } from 'react';
import { db } from '../Firebase/firebase-config'; // Adjust the import path
import { collection, getDocs, setDoc, doc, query, limit } from 'firebase/firestore';


const DataTest = () => {

  async function copyEmailsAndGradesToBsrMembers() {
    try {
      const performersRef = collection(db, 'Performers_v3');
      const snapshot = await getDocs(performersRef);
  
      if (snapshot.empty) {
        console.log('No matching documents in Performers_v3.');
        return;
      }
  
      for (const docSnapshot of snapshot.docs) {
        const data = docSnapshot.data();
        const email = data.email; // Ensure the field name matches exactly
        const grade = data.grade; // Ensure the field name matches exactly
  
        if (email) {
          const newDocRef = doc(db, 'BsrMembers_Production', docSnapshot.id);
          try {
            const newData = { Email: email };
            if (grade) newData.Grade = grade; // Add grade to the document if it exists
  
            await setDoc(newDocRef, newData);
            console.log(`Document for ${email} (${docSnapshot.id}) with grade ${grade || 'N/A'} written successfully to BsrMembers_Production.`);
          } catch (error) {
            console.error(`Error writing document for ${email} (${docSnapshot.id}):`, error);
          }
        } else {
          console.log(`No email found for document ID: ${docSnapshot.id}`);
        }
      }
  
      console.log('Processed all documents for BsrMembers_Production.');
    } catch (error) {
      console.error('Error copying emails and grades:', error);
    }
  }
  
  // copyEmailsAndGradesToBsrMembers();
   
        
    return (
      <div>
        
      </div>
    );
  };
  
  export default DataTest;