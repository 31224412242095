import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { storage } from '../Firebase/firebase-config';
import { ref as storageRef, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc,  getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase/firebase-config'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom';


import '../CSS/css@3.css' // Adjust the path as necessary
import SkillsForm from '../Components/SkillsForm';
import CreditsSection from '../Components/Credits Section';
import ImageCropperWithButton from '../Components/ImageCropperWithButton';
import ActionShots from '../Components/ActionShots';
import ShowReel from '../Components/ShowReel';
import logo from '../stunt-connect-logo.jpg';
import '../App.css'

//const storageRef = storage.ref();

const LogoutButton = styled.button`
  max-width: 100px;
  float: right;
  margin: 10px; // Adjust margin as needed
`;


// React component
const DataUploader = () => {

  const navigate = useNavigate();


  //STATE
  const [email, setEmail] = useState('');
  const [userDoc, setUserDoc] = useState(null); // State for user document data
  const [userDocRef, setUserDocRef] = useState(null); // State to store user document data
  const [skills, setSkills] = useState([]);
  const [croppedImages, setCroppedImages] = useState({
    headshot: null,
    fullBody: null,
    profileHeader: null
  });
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actionShotImages, setActionShotImages] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [phone, setPhone] = useState(null);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    heightFeet: '-',
    heightInches: '-',
    chest: '-',
    collar: '-',
    waist: '-',
    insideLeg: '-',
    shoeSize: '-',
    eyeColor: '-',
    hairColor: '-',
    sex: '-',
    ethnicity: '-'
  });

  // const [heightFeet, setHeightFeet] = useState([]);
  // const [heightInches, setHeightInches] = useState([]);
  // const [chest, setChest] = useState([]);
  // const [collar, setCollar] = useState([]);
  // const [waist, setWaist] = useState([]);
  // const [insideLeg, setInsideLeg] = useState([]);

  // const [shoeSize, setShoeSize] = useState([]);
  // const [eyeColor, setEyeColor] = useState([]);
  // const [hairColor, setHairColor] = useState([]);
  // const [sex, setSex] = useState([]);
  // const [ethnicity, setEthnicity] = useState([]);

  
  useEffect(() => {
    const auth = getAuth();
  
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        const userDocRef = doc(db, 'Performers_v4', user.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            console.log('Document data:', docSnap.data());
            const userData = docSnap.data();
            setUserDoc(userData);
  

            // Extracting first and last names
            const fullName = userData.fullName || '';
            const nameParts = fullName.trim().split(' ');
            const extractedFirstName = nameParts.shift(); // gets the first name part
            const extractedLastName = nameParts.join(' '); // joins the remaining parts as last name

            // Other fields
            // Assuming you have state variables for each field
            const { feet, inches } = userDoc ? convertCmToFeetInches(userDoc.heightCm || 0) : { feet: 0, inches: 0 };

            setFormValues({
              ...formValues,
              firstName: extractedFirstName,
              lastName: extractedLastName,
              heightFeet: feet.toString(),
              heightInches: inches.toString(),
              chest: userData.chest || '-',
              collar: userData.collar || '-',
              waist: userData.waist || '-',
              insideLeg: userData.insideLeg || '-',
              shoeSize: userData.shoeSize || '-',
              eyeColor: userData.eyeColor || '-',
              hairColor: userData.hairColor || '-',
              sex: userData.sex || '-',
              ethnicity: userData.ethnicity || '-',
            });
            
            setUserDocRef(userDocRef);
          } else {
            // Handle the case where there is no userDoc
          }
        } catch (error) {
          console.error('Error fetching or creating user document:', error);
        }
      } else {
        console.log('No user logged in');
      }
    });
  
    return () => unsubscribe();
  }, []);

function convertCmToFeetInches(cm) {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);

  return { feet, inches };
}

const handleFirstNameChange = (event) => {
  setFirstName(event.target.value);
};

const handleLastNameChange = (event) => {
  setLastName(event.target.value);
};

const handlePhoneChange = (event) => {
  setPhone(event.target.value);
};

const handleChange = (event) => {
  const { name, value } = event.target;
  setFormValues(prevValues => ({
    ...prevValues,
    [name]: value
  }));
};

  const handlePerfomerData = () => {
    if (userDoc) {
      console.log('Performer data:', userDoc);
      // Handle the performer data as needed
    }
  };

  const handleSkillsChange = (newSkills) => {
    setSelectedSkills(newSkills);
  };

  const handleActionShotFiles = (files) => {
    setActionShotImages(files);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleImageCropped = (title, data) => {
    // You can add additional logic here if needed
    console.log('Cropped image data:', data);
    setCroppedImages(prev => ({ ...prev, [title]: data }));
  };

  // Convert Data URL to Blob
  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading

  
    console.log("Cropped Images at Submit:", croppedImages);
  
    const formData = new FormData(event.target); // Ensure formData is defined
    let performer = {}; // Define performer at the top
    let heightFeet = 0;
    let heightInches = 0;
    let imageUrls = {}; // Define imageUrls
    let fileIds = {}; // Define fileIds
    let actionShotUrls = [];

    for (let [key, value] of formData.entries()) {
        if (key === "firstName" || key === "lastName") {
            // Concatenate first and last names
            performer['fullName'] = (performer['fullName'] || '') + " " + value;
        } else if (key === "heightFeet") {
            heightFeet = parseInt(value) || 0;  // Convert to integer, default to 0 if not a number
        } else if (key === "heightInches") {
            heightInches = parseInt(value) || 0;  // Convert to integer, default to 0 if not a number
        } else if (value !== " - ") {
            performer[key] = value;
        }
    }

    // Convert height to cm and add to performer object
    if (heightFeet !== 0 || heightInches !== 0) {
      const heightCm = (heightFeet * 30.48) + (heightInches * 2.54);
      performer['heightCm'] = Math.round(heightCm); // Round to nearest whole number
    }
    performer.phone = formData.get('phone');
    performer.imdbUrl = formData.get('imdbUrl');
    performer.showReelUrl = formData.get('showReelUrl');
    performer.imageUrls = imageUrls;
    performer.fileIds = fileIds;
    performer.skills = selectedSkills;
    performer.grade = formData.get('grade');
  
    let uploadPromises = [];
    let actionShotUploadPromises = [];

    Object.entries(croppedImages).forEach(([key, imageData]) => {
        if (imageData) {
            const blobData = dataURLtoBlob(imageData);

            let folderPath;
            switch (key) {
                case 'Headshot':
                    folderPath = 'Headshots';
                    break;
                case 'FullBody':
                    folderPath = 'FullBody';
                    break;
                case 'ProfileHeader':
                    folderPath = 'ProfileHeaders';
                    break;
                default:
                    folderPath = 'Other';
                    break;
            }

            uploadPromises.push(uploadImage(blobData, folderPath));
        }
    });

    // Handling action shot images
    actionShotImages.forEach((file, i) => {
      const fileName = `${Date.now()}_${i}_${file.name}`;
      actionShotUploadPromises.push(uploadImage(file, `ActionShots/${fileName}`));
  });

  try {
      const croppedUploadResults = await Promise.all(uploadPromises);
      
      croppedUploadResults.forEach((result, index) => {
          // Assuming the result object contains 'url' and 'fileId'
          const key = Object.keys(croppedImages)[index];
          imageUrls[key.toLowerCase()] = result.url; // Use key for mapping
          fileIds[key.toLowerCase()] = result.fileId;
      });

      const actionShotUploadResults = await Promise.all(actionShotUploadPromises);
        actionShotUploadResults.forEach(({ url, fileId }, index) => {
            const key = `actionShot${index}`;
            imageUrls[key] = url; // Storing in imageUrls if needed
            fileIds[key] = fileId; // Storing in fileIds if needed
            actionShotUrls.push(url); // Storing URL in actionShotUrls
        });

      // ... rest of your code ...
  } catch (error) {
      console.error("Error in uploading files: ", error);
      setIsLoading(false);
      return;
  }

    //Handle Show Reel URL
    const showReelUrl = formData.get('showReelUrl');
    performer.showReelUrl = showReelUrl;
  
    // Add image URLs to performer object
    performer.imageUrls = imageUrls;
    performer.fileIds = fileIds;
  
    // Upload performer data to Firestore
    performer.actionShotUrls = actionShotUrls;
    console.log("Performer data to upload:", performer);

    const auth = getAuth();
    const user = auth.currentUser;


    // Upload performer data to Firestore
    console.log("User:", user.uid);
      try {
        if (user.uid) {
            const docRef = doc(db, "Performers_v4", user.uid);
            await updateDoc(docRef, {
                ...performer, // Now performer includes actionShotUrls
            });
            console.log("Performer data updated successfully");
            navigate('/preview', { state: { performer: performer } });
        } else {
            console.error("No document to update");
        }
    } catch (error) {
        console.error("Error uploading performer data: ", error);
    }
    
    setIsLoading(false); // End loading
  };
  
    const uploadImage = async (imageData, folderPath) => {
      console.log("Image data to upload:", imageData);

        if (imageData instanceof Blob) {
            console.log(`File size: ${imageData.size} bytes`);
        }
      const fileId = `${Date.now()}`;
      const fileName = `${fileId}.jpg`; // Append .jpg to the file name
      const imageRef = storageRef(storage, `${folderPath}/${fileName}`);
  
      // Set the correct MIME type for JPEG
      const metadata = {
          contentType: 'image/jpeg',
      };
  
      const uploadTask = uploadBytesResumable(imageRef, imageData, metadata);
  
      return new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
              (snapshot) => {
                console.log("Upload snapshot:", snapshot);
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log(`Uploading: ${progress}% done`);
              },
              (error) => {
                  console.error(`Error uploading:`, error);
                  reject(error);
              },
              async () => {
                  try {
                      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                      console.log(`Upload complete. File available at: ${downloadURL}`);
                      resolve({ url: downloadURL, fileId });
                  } catch (error) {
                      console.error(`Error getting download URL:`, error);
                      reject(error);
                  }
              }
          );
      });
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('Logged out successfully');
      // Redirect to login page or handle the UI change here
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  return ( 
    <FullPageContainer>
      {isLoading && (
      <div className="loadingOverlay">
        <div className="spinner"></div>
      </div>
    )}
    <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      <PageContainer>
        <LogoImage src= { logo } alt="Logo" />
        <Title>Preformer Data Uploader</Title>
        <Subtitle>Update Data Below</Subtitle>
        <Form onSubmit={handleSubmit}>
        <h4>Personal Info</h4>
          <FormSection>
            <FormGroup>
              {/* ROW ONE */}
              <InputRow>
                  <Input id="firstName" 
                        name="firstName" 
                        type="text" 
                        value={firstName || ''}
                        placeholder="First name"
                        onChange={handleFirstNameChange} 
                        />

                  <Input id="lastName" 
                        name="lastName" 
                        type="text" 
                        value={lastName || ''}
                        placeholder="Last name"
                        onChange={handleLastNameChange}
                        />
              </InputRow>
              {/* ROW TWO */}
              <InputRow>
              <Input 
                  id="email" 
                  name="email" 
                  type="text" 
                  placeholder="Email" 
                  value={email} // Controlled component
                  onChange={handleEmailChange} // Update state on change
                />
                <Input 
                  id="phone" 
                  name="phone" 
                  type="text" 
                  placeholder="Phone" 
                  value={phone} // Controlled component
                  onChange={handlePhoneChange} // Update state on change
                />
              </InputRow>
              {/* ROW THREE */}
              <InputRow>
                <InputGroup>
                    <Label htmlFor="heightFeet">Height (Feet)</Label>
                    <BootstrapSelect id="heightFeet" name="heightFeet"  value={formValues.heightFeet} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="1">1'</option>
                    <option value="2">2'</option>
                    <option value="3">3'</option>
                    <option value="4">4'</option>
                    <option value="5">5'</option>
                    <option value="6">6'</option>
                    <option value="7">7'</option>
                    <option value="8">8'</option>
                    <option value="9">9'</option>
                    <option value="10">10'</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="heightInches">Height (Feet)</Label>
                    <BootstrapSelect id="heightInches" name="heightInches" value={formValues.heightInches} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="1">1"</option>
                    <option value="2">2"</option>
                    <option value="3">3"</option>
                    <option value="4">4"</option>
                    <option value="5">5"</option>
                    <option value="6">6"</option>
                    <option value="7">7"</option>
                    <option value="8">8"</option>
                    <option value="9">9"</option>
                    <option value="10">10"</option>
                    <option value="11">11"</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="grade">Grade</Label>
                    <BootstrapSelect id="grade" name="grade" aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="0">Probationary</option>
                    <option value="1">Stunt Performer</option>
                    <option value="2">Senior Stunt Performer</option>
                    <option value="3">Key Stunt Performer</option>
                    <option value="4">Full Member</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="chest">Chest (Inches)</Label>
                    <BootstrapSelect id="chest" name="chest" value={formValues.chest} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="34">34"</option>
                    <option value="36">36"</option>
                    <option value="38">38"</option>
                    <option value="40">40"</option>
                    <option value="42">42"</option>
                    <option value="44">44"</option>
                    <option value="46">46"</option>
                    <option value="48">48"</option>
                    <option value="50">50"</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="collar">Collar (Inches)</Label>
                    <BootstrapSelect id="collar" name="collar" value={formValues.collar} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="14">14"</option>
                    <option value="14.5">14.5"</option>
                    <option value="15">15"</option>
                    <option value="15.5">15.5"</option>
                    <option value="16">16"</option>
                    <option value="16.5">16.5"</option>
                    <option value="17">17"</option>
                    <option value="17.5">17.5"</option>
                    <option value="18">18"</option>
                    </BootstrapSelect>
                  </InputGroup>
              </InputRow>
              {/* ROW FOUR */}
              <InputRow>
                <InputGroup>
                    <Label htmlFor="waist">Waist (Inches)</Label>
                    <BootstrapSelect id="waist" name="waist" value={formValues.waist} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="23">23"</option>
                    <option value="24">24"</option>
                    <option value="25">25"</option>
                    <option value="26">26"</option>
                    <option value="27">27"</option>
                    <option value="28">28"</option>
                    <option value="30">30"</option>
                    <option value="32">32"</option>
                    <option value="34">34"</option>
                    <option value="36">36"</option>
                    <option value="38">38"</option>
                    <option value="40">40"</option>
                    <option value="42">42"</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="insideLeg">Inside Leg (Inches)</Label>
                    <BootstrapSelect id="insideLeg" name="insideLeg" value={formValues.insideLeg} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="28">28"</option>
                    <option value="29">29"</option>
                    <option value="30">30"</option>
                    <option value="31">31"</option>
                    <option value="32">32"</option>
                    <option value="33">33"</option>
                    <option value="34">34"</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="shoeSize">Shoe Size</Label>
                    <BootstrapSelect id="shoeSize" name="shoeSize" value={formValues.shoeSize} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="5">5</option>
                    <option value="5.5">5.5</option>
                    <option value="6">6</option>
                    <option value="6.5">6.5</option>
                    <option value="7">7</option>
                    <option value="7.5">7.5</option>
                    <option value="8">8</option>
                    <option value="8.5">8.5</option>
                    <option value="9">9</option>
                    <option value="9.5">9.5</option>
                    <option value="10">10</option>
                    <option value="10.5">10.5</option>
                    <option value="11">11</option>
                    <option value="11.5">11.5</option>
                    <option value="12">12</option>
                    <option value="12.5">12.5</option>
                    <option value="13">13</option>
                    </BootstrapSelect>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="eyeColor">Eye Color</Label>
                    <BootstrapSelect id="eyeColor" name="eyeColor" value={formValues.eyeColor} onChange={handleChange} aria-label="Default select example">
                    <option value="-"> - </option>
                    <option value="Blue">Blue</option>
                    <option value="Brown">Brown</option>
                    <option value="Dark Brown">Dark Brown</option>
                    <option value="Green">Green</option>
                    <option value="Gray">Gray</option>
                    <option value="Hazel">Hazel</option>
                    <option value="Amber">Amber</option>
                    </BootstrapSelect>
                  </InputGroup>
              </InputRow>
              <InputRow>
                <Label htmlFor="hairColor">Hair Color</Label>
                <BootstrapSelect id="hairColor" name="hairColor" value={formValues.hairColor} onChange={handleChange} aria-label="Default select example">
                <option value="-"> - </option>
                <option value="Blonde">Blonde</option>
                <option value="Brown">Brown</option>
                <option value="Gray">Gray</option>
                <option value="Ginger">Ginger</option>
                <option value="Black">Black</option>
                </BootstrapSelect>

                <Label htmlFor="sex">Sex</Label>
                <BootstrapSelect id="sex" name="sex" value={formValues.sex} onChange={handleChange} aria-label="Default select example">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                </BootstrapSelect>

                <Label htmlFor="sex">Ethnicity</Label>
                <BootstrapSelect id="ethnicity" name="ethnicity"value={formValues.ethnicity} onChange={handleChange} aria-label="Default select example">
                <option value="-">-</option>
                <option value="White">White</option>
                <option value="Asian">Asian</option>
                <option value="Black">Black</option>
                <option value="Caribbean">Caribbean</option>
                <option value="Mixed">Mixed</option>
                <option value="Mediteranian">Mediteranian</option> 
                <option value="Other">Other</option>   
                </BootstrapSelect>
              </InputRow>
            </FormGroup>
          </FormSection>
          <Divider />
          <FormSection>
            <SkillsForm onSkillsChange={handleSkillsChange} />
            {/* <SkillsForm onSkillsChange={setSelectedSkills} /> */}
          </FormSection>
          <Divider />
          <FormSection>
          <CreditsSection />
          </FormSection>
          <Divider />
          <FormSection>
              <h4>Images</h4>
          </FormSection>
          <FormSection>
          <ImageCropperWithButton 
            title="Headshot" 
            requiredWidth={700} 
            requiredHeight={700}  
            onImageCropped={handleImageCropped}
            />
          </FormSection>
          <Divider />
          <FormSection>
              <ImageCropperWithButton 
              title="Full Body" 
              requiredWidth={700} 
              requiredHeight={1333} 
              onImageCropped={handleImageCropped}
            />
          </FormSection>
          <Divider />
          <FormSection>
              <ImageCropperWithButton 
              title="Profile Header" 
              requiredWidth={1179} 
              requiredHeight={750}
              onImageCropped={handleImageCropped} 
              />
          </FormSection>
          <Divider />
          <FormSection>
            <ActionShots onFilesSelected={handleActionShotFiles} />
          </FormSection>
          <Divider />
          <FormSection>
              <ShowReel />
          </FormSection>
          <Divider />
          
          <Button type="submit">Submit</Button>
        </Form>
      </PageContainer>
    </FullPageContainer>
  );
};

export default DataUploader;

// Styled components
const FullPageContainer = styled.div`
  background-color: #1a1a1a;
  min-height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 50%;
`;

// Logo Image Component
const LogoImage = styled.img`
  width: 100px; // Set this to your desired size
  height: auto;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin: 10px 0;
`;

const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-weight: normal;
`;

const Form = styled.form`
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 5px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  &:not(:last-child) {
    margin-bottom: 20px; // Add space between input rows
`;

const Label = styled.label`
  display: block;
  text-align: left;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-right: 20px;
  margin-top: 5px;
  border-radius: 5px;
  border: none;
  color: black;
  border-radius: 4px;
  border-color: #495057; 
  background-color: #212529;
  color: #fff;
  flex: 1;
  min-width: 0; // Prevent flex items from growing beyond their content on smaller screens

  &:not(:last-child) {
    margin-right: 10px; // Add space between inputs if they're not stacked
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-right: 0; // Remove the right margin on smaller screens
    }
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;

    & > label {
      flex-basis: 30%;
      margin-right: 5%;
    }

    & > select {
      flex-basis: 65%;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;

    & > label {
      margin-bottom: 0.5rem;
    }
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #712cf9;
  color: white;
  margin-top: 20px;
  cursor: pointer;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 0.25rem;
  background-color: #212529;
  color: white;
`;

const BootstrapSelect = styled.select.attrs({
  className: 'form-select'
})`
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-color: #495057; 
    background-color: #212529;
    color: #fff;
    min-width: 0; // Prevent flex items from growing beyond their content on smaller screens

    &:not(:last-child) {
      margin-right: 10px; // Add space between inputs if they're not stacked
    }

    @media (max-width: 768px) {
      &:not(:last-child) {
        margin-right: 0; // Remove the right margin on smaller screens
      }
    }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: #ffffff; // or any color that fits the form's style
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const FormSection = styled.div`
  margin-bottom: 2rem;
`;


