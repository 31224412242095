import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate} from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';




const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
  
    const email = event.target.email.value; // Assuming you have an input field for email with 'name' as 'email'
    const password = event.target.password.value; // Assuming you have an input field for password with 'name' as 'password'
  
    const auth = getAuth();
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Login successful
      const user = userCredential.user;
      console.log('Logged in:', user);
      // Redirect to homepage or dashboard here
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error.message);
      // Handle errors here, such as displaying a notification to the user
    }
  };
  
  return (
    <PageWrapper>
      <ImageContainer />
      <FormContainer>
        <Form onSubmit={handleLogin}>
          <Title>Login</Title>
          <Input
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <MarginDivider />
          <Button type="submit">Sign In</Button>
          <SignUpPrompt>
            Don't have an account? <StyledLink to="/signup">Sign up here</StyledLink>
          </SignUpPrompt>
        </Form>
      </FormContainer>
    </PageWrapper>
  );
};

export default LoginPage;

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #1a1a1a;
`;

const ImageContainer = styled.div`
  flex: 1;
  background-image: url('web-lg-logo.png');
  background-size: 50%;               // Scales the background image
  background-repeat: no-repeat;
  background-position: center center; // Centers the background image
  display: none;                      // Starts with display none

  @media (min-width: 768px) {
    display: flex;                    // Makes the container a flex container
    justify-content: center;          // Centers children horizontally in the container
    align-items: center;              // Centers children vertically in the container
  }
`;


const FormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  max-width: 300px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Add this line */
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  box-sizing: border-box; /* Add this line */

  &:hover {
    background-color: #0056b3;
  }
`;

const Title = styled.h1`
  color: white;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
`;

const GoogleSignInButton = styled(Button)`
  background-color: #db4437;
  margin-top: 10px;

  &:hover {
    background-color: #a33222;
  }
`;

const MarginDivider = styled.div`
  height: 1px;
  background-color: #ddd; // Use a color that suits your design
  margin: 20px 0; // Adjust top and bottom margin as needed
`;

const SignUpPrompt = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white; /* Set the text color to white */
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
`;

// If you want the Link to also have white text
const StyledLink = styled(Link)`
  color: white;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline from links */

  &:hover {
    text-decoration: underline; /* Optional: adds underline on hover */
  }
`;