import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  // Add any additional container styles here
`;

const Title = styled.h4.attrs({
  className: 'mb-3', // Bootstrap class
})``;

const StyledLabel = styled.label`
  // Add any additional label styles here
`;

const BoldText = styled.p.attrs({
  style: { fontWeight: 'bold' }, // Inline style
})``;

const ItalicText = styled.p.attrs({
  style: { fontStyle: 'italic' }, // Inline style
})``;

const InputGroup = styled.div.attrs({
  className: 'col-sm-6', // Bootstrap class
})``;

const StyledInput = styled.input.attrs({
  type: 'text',
  className: 'form-control', // Bootstrap class
  id: 'imdb-name',
  placeholder: 'IMDB URL e.g. https://www.imdb.com/name/nm0000323',
})`
  border-radius: 4px;
  border-color: #495057; 
  background-color: #212529;
  color: white;
  `;

const ImageWrapper = styled.div.attrs({
  className: 'col-sm-6', // Bootstrap column class
})`
  display: flex;
  justify-content: flex-end; // Aligns content to the end of the container (right side)
  img {
    max-width: 150px;
    height: auto; // Maintain aspect ratio
  }
`;

const FullWidthImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end; // Aligns content to the right
  img {
    max-width: 150px;
    height: auto; // Maintain aspect ratio
  }
`;

function CreditComponent() {
    return (
        <Container>
            <Title>Credits</Title>
            
            <StyledLabel>
                <BoldText>
                    We can save you some time here!
                </BoldText>
                <ItalicText>
                    We verify credits through IMDB to ensure only verified credits are added. Please enter your IMDB URL below and our system will do the rest!!
                </ItalicText>
            </StyledLabel>

            <InputGroup>
                <StyledLabel htmlFor="imdb-name">
                    IMDB URL
                </StyledLabel>
                <StyledInput />
            </InputGroup>
            <FullWidthImageWrapper>
                <img src="IMDB_Logo_2016.svg.png" alt="IMDB Logo" />
            </FullWidthImageWrapper>
        </Container>
    );
}

export default CreditComponent;
