import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { storage, db } from '../Firebase/firebase-config';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate, useLocation  } from 'react-router-dom';


import '../CSS/css@3.css' // Adjust the path as necessary
import logo from '../stunt-connect-logo.jpg';
import '../App.css'



const WrongEmail = () => {
    const navigate = useNavigate();

    const email = "members@britishstuntregister.com"; // Replace with your recipient's email
    const ccEmail = "info@stuntconnect.com";
    const subject = encodeURIComponent("Stunt Connect App");
    const body = encodeURIComponent("Hi BSR Committee, \nI'm trying to signup to Stunt Connect but they don't have a record of my email or grade. \n\nPlease can you share my email and grade with Stunt Connect. \n\nThanks");

    const mailtoLink = `mailto:${email}?cc=${ccEmail}&subject=${subject}&body=${body}`;

    const handleEmailClick = () => {
      window.location.href = mailtoLink;
  };


    return (
        <FullPageContainer>
            <PageContainer>
                <LogoImage src= { logo } alt="Logo" />
                <Title>Stunt Connect</Title>
                <Container>
                <Subtitle>Thanks for trying to signup, unfortunatley the email you used isn't one we have verified by the British Stunt Register. To be on the app you must be a member of the BSR and signup with the email published by the BSR.</Subtitle>
                <Subtitle> If you are using you're regstered email then you will need to contact the office and ask them to share you're email and grade with Stunt Connect.  </Subtitle>
                <Subtitle> Click the button below to create an email to send. Any problems please email info@stuntconnect.com </Subtitle>
                </Container>
        <hr className="my-4" />

        <Button onClick={handleEmailClick}>Contact British Stunt Register</Button>

        </PageContainer>
    </FullPageContainer>
        
    );
};

export default WrongEmail;

// Styled components


const FullPageContainer = styled.div`
  background-color: #1a1a1a;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: calc(100% - 60px); // Subtract 30px margin from each side
  margin: 30px auto; // Apply 30px margin on all sides
  padding: 20px;

  @media (min-width: 768px) {
    width: auto; // Let the container expand more freely
    max-width: 100%; // Remove the max-width restriction
    margin: 30px; // Keep the 30px margin
  }
`;

// Logo Image Component
const LogoImage = styled.img`
  width: 100px; // Set this to your desired size
  height: auto;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin: 10px 0;
`;

const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
  Text-align: center;
`;

const Label = styled.label`
  display: block;
  text-align: left;
`;


const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: lightgray; // Adjust the color as needed
  width: 100%; // Adjust the width as needed
  margin: 20px 0; // Adjust the margin as needed
`;

const Container = styled.div`
    display: column;
    max-width: 500px;
    width: 100%;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #712cf9;
  color: white;
  margin-top: 20px;
  cursor: pointer;
`;