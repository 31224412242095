import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';


const ActionShots = ({ onFilesSelected }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);



  const onFileChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setSelectedFiles(files); // Store the File objects

      // Call the onFilesSelected callback if it's provided
      if (onFilesSelected) {
        onFilesSelected(files);
      }

      e.target.value = ''; // Reset the file input after selection
    }
  };

  return (
    <ActionShotsContainer>
      <LeftContainer>
        <Title>Action Shots</Title>
        <Description>
          Here you can add up to 3 action shots. These will be displayed within the profile under the media tab.
        </Description>
        <ItalicText>
          Please bear in mind these images will only be displayed on a mobile device and so shouldn't be any larger than 1280x720. Larger images will be downsized by the system anyway.
        </ItalicText>
        <StyledLabel htmlFor="actionShotsInput">Choose up to 3 images</StyledLabel>
        <input
          type="file"
          accept="image/*"
          onChange={onFileChange}
          multiple
          style={{ display: 'none' }}
          id="actionShotsInput"
        />
        <Button
          variant="primary"
          onClick={() => document.getElementById('actionShotsInput').click()}
        >
          Choose Images
        </Button>
      </LeftContainer>
      <ThumbnailsContainer>
        {selectedFiles.map((file, index) => {
          // Create an object URL for each file
          const thumbnailUrl = URL.createObjectURL(file);
          return <Thumbnail key={index} src={thumbnailUrl} alt={`Thumbnail ${index + 1}`} />;
        })}
      </ThumbnailsContainer>

    </ActionShotsContainer>
  );
};

export default ActionShots;


const ActionShotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 300px; // Adjust this width based on your layout
`;

const Thumbnail = styled.img`
  width: 100px; // Thumbnail size
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
`;

const Title = styled.h5`
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin-bottom: 10px;
`;

const ItalicText = styled.p`
  font-style: italic;
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  margin-bottom: 10px;
`;