import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../Firebase/firebase-config';
import { collection, addDoc,  getFirestore, doc, getDoc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { auth } from '../Firebase/firebase-config'; 



const SignUpPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  

  const checkEmailExists = async (emailToMatch) => {
    const membersCollectionRef = collection(db, 'BsrMembers_Production');
    const q = query(membersCollectionRef, where('Email', '==', emailToMatch));
  
    try {
      const querySnapshot = await getDocs(q);
      const isEmailFound = !querySnapshot.empty;
      if (isEmailFound) {
        // Email exists in the collection
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // User created successfully, userCredential.user will contain the new user's information
            console.log('User created successfully:', userCredential.user);
            const db = getFirestore();
            const user = userCredential.user; // Assuming userCredential is from createUserWithEmailAndPassword

            try {
              // Create a reference to the document location you want to set
              const userDocRef = doc(db, "Performers_v4", user.uid);

              // Set the document at this location with your data
              await setDoc(userDocRef, {
                email: user.email // use the email of the signed-up user
              });

              console.log("Document written with ID: ", user.uid);
              navigate('/');
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          

            // Here, you can handle redirection or additional setup steps
          } catch (error) {
            // Handle errors here, such as email already in use, weak password, etc.
            // toast.error(error.message, {
            //   position: "top-center",
            //   autoClose: 5000,
            // });
            if (error.code == "auth/email-already-in-use") {
              toast.error("Looks like you already have an account. Please login.");
            } else if (error.code == "auth/weak-password") {
              toast.error("Weak password");
            }
            console.log(error);
          }
      } else {
        // Email does not exist in the collection
        console.log("Email not found!!");
        navigate('/wrongEmail');
      }
      return isEmailFound;
    } catch (error) {
      console.error('Error searching for email:', error);
      throw error;
    }
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    
    if (password !== confirmPassword) {
      console.error("Passwords don't match");
      toast.error("Your Passwords don't match");
      return;
    }

    const emailToMatch = email.toLowerCase();

    checkEmailExists(emailToMatch)
      .then((exists) => {
        if (exists) {
          // Proceed with the user creation
        
        } else {
          // Handle email not found case
          // 
        }
      })
      .catch((error) => {
        console.error('Failed to check email:', error);
        toast.error('Error checking email');
      });
  };
      
  return (
    <PageWrapper>
      <ImageContainer />
      <FormContainer>
        <Form onSubmit={handleSignUp}>
          <Title>Signup</Title>
          <Input
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <Input
            type="password"
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />

          <MarginDivider />
          <Button type="submit">Sign Up</Button>
          <SignUpPrompt>
            Don't have an account? <StyledLink to="/signin">Already have an account? Login Here</StyledLink>
          </SignUpPrompt>
          <SignUpPrompt>
            Having issues? Please contact us at <a href="mailto:info@stuntconnect.com">info@stuntconnect.com</a>
          </SignUpPrompt>
        </Form>
      </FormContainer>
    </PageWrapper>
  );
};

export default SignUpPage;

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #1a1a1a;
`;

const ImageContainer = styled.div`
  flex: 1;
  background-image: url('web-lg-logo.png');
  background-size: 50%;               // Scales the background image
  background-repeat: no-repeat;
  background-position: center center; // Centers the background image
  display: none;                      // Starts with display none

  @media (min-width: 768px) {
    display: flex;                    // Makes the container a flex container
    justify-content: center;          // Centers children horizontally in the container
    align-items: center;              // Centers children vertically in the container
  }
`;


const FormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  max-width: 300px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Add this line */
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  box-sizing: border-box; /* Add this line */

  &:hover {
    background-color: #0056b3;
  }
`;

const Title = styled.h1`
  color: white;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
`;

const GoogleSignInButton = styled(Button)`
  background-color: #db4437;
  margin-top: 10px;

  &:hover {
    background-color: #a33222;
  }
`;

const MarginDivider = styled.div`
  height: 1px;
  background-color: #ddd; // Use a color that suits your design
  margin: 20px 0; // Adjust top and bottom margin as needed
`;

const SignUpPrompt = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white; /* Set the text color to white */
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
`;

// If you want the Link to also have white text
const StyledLink = styled(Link)`
  color: white;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline from links */

  &:hover {
    text-decoration: underline; /* Optional: adds underline on hover */
  }
`;