import React, { useState } from 'react';
import styled from 'styled-components';

const SkillTag = styled.div`
  display: inline-flex;
  align-items: left;
  padding: 5px 10px;
  background-color: #2a2a2a; // Skill tag background color
  border-radius: 15px;
  margin: 5px;
`;

const SkillName = styled.span`
  margin-right: 10px;
`;

const CloseButton = styled.button`
  background-color: red;
  border: none;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
  cursor: pointer;
  line-height: 1;

  &:hover {
    background-color: darkred;
  }
`;
const StyledInput = styled.select`
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #495057; /* Border color for better readability */
  background-color: #212529; /* Background color for the input */
  color: #fff; /* Text color for better readability */
`;

// You can remove the SkillsContainer if you don't need any specific styling,
// or keep it if you want to apply padding or other styles
const SkillsContainer = styled.div`
  max-width: 500px; // Adjust as necessary
  padding: 20px; // Provide some spacing
`;


const SkillsForm = ({ onSkillsChange }) => {
  const [skills, setSkills] = useState([]);

  const addSkill = (skill) => {
    if (!skills.includes(skill) && skill) {
      const newSkills = [...skills, skill];
      setSkills(newSkills);
      onSkillsChange(newSkills); // Notify parent component of the change
    }
  };

  const removeSkill = (skillToRemove) => {
    const newSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(newSkills);
    onSkillsChange(newSkills); // Notify parent component of the change
  };

  return (
    <SkillsContainer>
      <h4>Skills</h4>
      <StyledInput className="form-select" onChange={(e) => addSkill(e.target.value)} defaultValue="">
        <option value="" disabled>Select a skill</option>
        
        <optgroup label="Combat">
                  <option value="Fight choreography">Fight choreography</option>
                  <option value="Sword  & Shield Work">Sword  & Shield Work</option>
                  <option value="Fencing">Fencing</option>
                  <option value="Jousting">Jousting</option>
                  <option value="Armoury">Armoury</option>
              </optgroup>
      
              <optgroup label="Falls" >
                <option value="Trampoline">Trampoline</option>
                <option value="High Dive">High Dive</option>
                <option value="Air Ram">Air Ram</option>
                <option value="Air Bag Falls">Air Bag Falls</option>
                <option value="Car Knockdowns">Car Knockdowns</option>
                <option value="Falls >50ft">Falls above 50ft</option>
                <option value="High Falls <50ft">High Falls below 50ft</option>
                <option value="High Dive 10m">High Dive 10m</option>
                <option value="Springboard">Springboard</option>
                <option value="Work at Heights">Work at Heights</option>
                <option value="Stair Falls">Stair Falls</option>
              </optgroup>

              <optgroup label="Horse Riding">
                  <option value="Horse Riding">Horse Riding</option>
                  <option value="Horse Master">Horse Master</option>
                  <option value="Coach/Chariot Driver">Coach/Chariot Driver</option>
                  <option value="Jousting">Jousting</option>
                  <option value="Dressage">Dressage</option>
                  
              </optgroup>
              <optgroup label="Driving">
                <option value="Motorcycle - Tricks">Motorcycle - Tricks</option>
                  <option value="Rally">Rally</option>
                  <option value="Precision Driving">Precision Driving</option>
                  <option value="Pipe Ramps">Pipe Ramps</option>
                  <option value="Drifting">Drifting</option>
                  <option value="Kart Racing">Kart Racing</option>
                  <option value="HGV">HGV</option>
                  <option value="Heavy Plant Operator">Heavy Plant Operator</option>
                  <option value="Farm Machinery Operator">Farm Machinery Operator</option>
                  <option value="Motorboat">Motorboat</option>
                  <option value="Powerboat">Powerboat</option>
                  <option value="Sailing">Sailing</option>
                  <option value="Water Ski">Water Ski</option>
            </optgroup>
           
            <optgroup label="Agility & Strength">
                  <option value="Gymnastics">Gymnastics</option>
                  <option value="Rock Climbing">Rock Climbing</option>
                  <option value="Dance">Dance</option>
                  <option value="Breakdance">Breakdance</option>
                  <option value="Bungee">Bungee</option>
                  <option value="Parkour">Parkour</option>
                  <option value="Tumbling">Tumbling</option>
            </optgroup>
           
            <optgroup label="Water">
              <option value="Swimming">Swimming</option>
              <option value="Sub Aqua">Sub Aqua</option>
              <option value="Advanced Diver">Advanced Diver</option>
              <option value="Cliff diving">Cliff diving</option>
              <option value="Motorboat">Motorboat</option>
              <option value="PADI Divemaster">PADI Divemaster</option>
              <option value="Powerboat">Powerboat</option>
              <option value="Sailing">Sailing</option>
              <option value="Surf">Surf</option>
              <option value="Wake Boarding">Wake Boarding</option>
              <option value="Water Ski">Water Ski</option>
              <option value="Wind-Surf">Wind-Surf</option>
            </optgroup>
            
            <optgroup label="Group 1">
              <option value="Air"></option>
                  <option value="Air Ram">Air Ram</option>
                  <option value="Bunji">Bunji</option>
                  <option value="Hot Air Balloon">Hot Air Balloon</option>
                  <option value="Microlight">Microlight</option>
                  <option value="Parachute">Parachute</option>
                  <option value="Synchronised Parachute"></option>
                  <option value="Paraglide">Paraglide</option>
                  <option value="Wing Suit">Wing Suit</option>
                  <option value="Synchronised Wing Suit">Synchronised Wing Suit</option>
          </optgroup>
         
          <optgroup label="Fire">
              <option value="Burns - Basic">Burns - Basic</option>
              <option value="Burns - Experienced">Burns - Experienced</option>
              <option value="Burns - Expert">Burns - Expert</option>
              <option value="Partial  Burns">Partial  Burns</option>
              <option value="Full Burns">Full Burns</option>
              <option value="Fire Safety">Fire Safety</option>
          </optgroup>
    
          <optgroup label="Other -Stunt Specific">
            <option value="2nd Unit Director">2nd Unit Director</option>
            <option value="Acting">Acting</option>
            <option value="Bungee">Bungee</option>
            <option value="Burns">Burns</option>
            <option value="First Aid">First Aid</option>
            <option value="Jerks">Jerks</option>
            <option value="Mo-Cap Performer">Mo-Cap Performer</option>
            <option value="Rigging">Rigging</option>
            <option value="Rigging Supervisor">Rigging Supervisor</option>
            <option value="Wire Work">Wire Work</option>
          </optgroup>

          <optgroup label="Other - Sports/Specialisms">
            <option value="Archery">Archery</option>
            <option value="Abseiling">Abseiling</option>
            <option value="Circus Skills">Circus Skills</option>
            <option value="Decathlon">Decathlon</option>
            <option value="Endurance Cycle">Endurance Cycle</option>
            <option value="Iron Man">Iron Man</option>
            <option value="Parkour">Parkour</option>
            <option value="Rock Climbing">Rock Climbing</option>
            <option value="Rugby">Rugby</option>
            <option value="Skiing">Skiing</option>
            <option value="Snowboarding">Snowboarding</option>
            <option value="Triathlon">Triathlon</option>
        </optgroup>
      </StyledInput>

      <div className="mt-3">
        {skills.map((skill, index) => (
          <SkillTag key={index}>
            <SkillName>{skill}</SkillName>
            <CloseButton onClick={() => removeSkill(skill)}>x</CloseButton>
          </SkillTag>
        ))}
      </div>
    </SkillsContainer>
  );
};

export default SkillsForm;

