import React, { useState } from 'react';
import styled from 'styled-components';

const ShowReelContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h5`
  margin-bottom: 10px;
`;

const Subtitle = styled.h6`
  margin-bottom: 10px;
`;

const ItalicLabel = styled.label`
  font-style: italic;
  margin-bottom: 5px;
  display: block;
`;

const StyledInput = styled.input`
  width: 100%; /* Adjust the width as needed */
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: white; /* Corrected property for text color */
  border-color: #495057;
  background-color: #212529;
`;


const ShowReel = () => {
  const [showReelUrl, setShowReelUrl] = useState('');

  const handleUrlChange = (e) => {
    setShowReelUrl(e.target.value);
  };

  return (
    <ShowReelContainer>
      <Title>Show Reel</Title>
      <Subtitle>Got a ShowReel you want on your profile?</Subtitle>
      <ItalicLabel>Stick a YouTube or Vimeo link below.</ItalicLabel>
      <label htmlFor="showReelUrl">Show Reel URL</label>
      <StyledInput
        type="text"
        id="showReelUrl"
        value={showReelUrl}
        onChange={handleUrlChange}
        placeholder="Enter URL here"
      />
    </ShowReelContainer>
  );
};

export default ShowReel;
