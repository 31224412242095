import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from './Firebase/firebase-config';
import HomePage from './Pages/HomePage';
import styled from 'styled-components';
import LoginPage from './Pages/LoginPage';
import SignUpPage from './Pages/SignupPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Preview from './Pages/Preview';
import Done from './Pages/Done';
import WrongEmail from './Pages/WrongEmail';
import DataTest from './Pages/DataTest';

// Styled components

function App() {
  const [currentUser, setCurrentUser] = useState(undefined); // undefined means the auth state is loading

  useEffect(() => {
    // Setting the persistence
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Subscribing to auth state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setCurrentUser(user);
        });
        return unsubscribe; // This function is called to unsubscribe to the listener when the component unmounts
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const isAuthenticated = currentUser != null;

  if (currentUser === undefined) {
    // Maybe return a loading screen or a spinner
    return <div>Loading...</div>;
  }

  

  const ProtectedRoute = ({ children, isAuthenticated }) => {
    return isAuthenticated ? children : <Navigate to="/signin" />;
};


  return (
      <>
        <Router>
      <Routes>
        <Route path="/signin" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />

        <Route path="/wrongEmail" element={<WrongEmail />} />
        <Route path="/data" element={<DataTest />} />

        <Route
          path="/"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/preview"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Preview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/done"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Done />
            </ProtectedRoute>
          }
        />
        {/* Redirect all non-matching routes to signin */}
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </Router>
        <ToastContainer position="top-center" autoClose={5000} />
      </>
    );
  }
export default App;